import { Component, h } from "preact";
import "bootstrap/dist/css/bootstrap.min.css";

export default class Index extends Component {
  render() {
    return (
      <div className="container">
        <h1>snood1205</h1>
        <a href="https://github.com/snood1205">GitHub</a>
      </div>
    );
  }
}
